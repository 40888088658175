// site/src/components/molecules/card/Card.tsx

import type { CardProps } from '.';

import { default as BsCard } from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/CardHeader';
import CardBody from 'react-bootstrap/CardBody';

import Spinner from 'react-bootstrap/Spinner';

import cn from 'classnames';
import classNames from 'classnames';
//import dynamic from 'next/dynamic';

//const Button = dynamic(() => import('@components/atoms/button'))
//const Icon = dynamic(() => import('@components/atoms/icon'))

const Card = ({ header, footer, children, loading = true, shadow = true, h100 = true, rounded = true, hasBorder = true, collapsible = false, ...props }: CardProps) => {
    const t = (str: string) => str

    return (
        <BsCard className={cn('w-100', [{ 'shadow': shadow, 'h-100': h100, 'rounded-0': !rounded, 'border-0': !hasBorder }])}>
            {false === loading ? (
                <>
                    {header && (
                        <CardHeader as="p" className={classNames('bg-white fs-5 fw-bold py-3', [{ 'd-flex justify-content-between  justify-content-md-start  w-100': collapsible }])} style={{ lineHeight: 1.2 }}>
                            {header}
                            {/* true === collapsible && <Button onClick={() => setOpen(open => !open)} icon={<Icon key={'card_chevron'} name={open ? 'chevron-up' : 'chevron-down'} size="sm" />} className={'bg-white border-0'} /> */}
                        </CardHeader>
                    )}
                    {children && (
                        collapsible ?
                            <CardBody {...props}>{children}</CardBody>
                            :
                            <CardBody {...props}>{children}</CardBody>
                    )}
                    {footer && (
                        <div className={'card-footer  bg-gray-100'}>
                            {footer}
                        </div>
                    )}
                </>
            ) : (
                <div className={'h-100 my-5 d-flex justify-content-center align-items-center'} style={props.style ?? {}}>
                    <div className={'h-100 d-flex flex-column justify-content-center align-items-center'}>
                        <Spinner animation="border" variant="secondary" />
                        <span className={'mt-2'}>{t('loading')}</span>
                    </div>
                </div>
            )}
        </BsCard>
    );
};

export default Card;
