import type { ImageProps } from '.';

import NextImage from 'next/image';
import getCDNImageUrl from '@helpers/get-cdn-image-url';

const CLOUDINARY_DOMAIN = `${process.env.CLOUDINARY_DOMAIN ?? ''}`

const Image = ({ src: propSrc, width, height, quality, cdn = null, ...props }: ImageProps) => {

    let src = propSrc;
    let unoptimized = props.unoptimized

    if (undefined === src) {
        return (
            <NextImage src={'/no-image.png'} {...(width && { width })} {...(height && { height })} quality={quality} unoptimized={unoptimized} {...props} style={{ color: '' }} />
        )
    }

    const regex = new RegExp([process.env.COMMERCE_STORE_DOMAIN, process.env.COMMERCE_MEDIA_DOMAIN].join('|'), 'g')

    if (null !== cdn) {
        unoptimized = true
        src = getCDNImageUrl({ src: `https://${CLOUDINARY_DOMAIN}/${cdn.locale}` + src, width, height, quality })
    } else if (Array.isArray(src.match(regex)) && (process.env.COMMERCE_STORE_DOMAIN || process.env.COMMERCE_MEDIA_DOMAIN)) {
        src = getCDNImageUrl({ src, width, height, quality })
    } else if (process.env.WIKI_DOMAIN && src.startsWith('\/w\/images') || src.startsWith('\/w\/thumb.php') || src.startsWith('\/w\/index.php')) {
        unoptimized = true
        src = getCDNImageUrl({ src: `https://${CLOUDINARY_DOMAIN}` + encodeURI(src.replace('/index.php', '')), width, height, quality })
    }

    return (
        <NextImage src={src} {...(width && { width })} {...(height && { height })} quality={quality} unoptimized={unoptimized} {...props} style={{ color: '' }} />
    )
}

export default Image;
