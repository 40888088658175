'use client';

import type { FormProps } from '.';

import React, { Children } from 'react';
import { Formik } from 'formik';
import BsForm from 'react-bootstrap/Form';
import FormControl from '@components/atoms/form-control';
import FormCheck from '@components/atoms/form-check';

const Form = ({
    children,
    initialValues = {},
    validationSchema,
    className,
    showErrors = true,
    ...props
}: FormProps) => {
    const renderChildren = ({ children, ...formProps }: any) => {
        return Children.map<React.ReactNode, React.ReactNode>(children, (child: any) => {
            let childProps: { children: any } = { children: null };
            if (React.isValidElement(child)) {
                if ('string' !== typeof child && 'number' !== typeof child && (FormControl === child.type || FormCheck === child.type)) {
                    return renderField(child, formProps);
                }
                const { props: { children } }: any = child; //FIXME - typing
                childProps['children'] = renderChildren({ children, ...formProps });
                return React.cloneElement(child as React.ReactElement<any>, childProps);
            }
            return child;
        });
    };

    const renderField: any = (child: any, { values, errors, handleChange, handleBlur, showErrors }: any) => {
        const { props: { id, onChange, value } } = child;
        return (
            <>
                {React.cloneElement(child as React.ReactElement<any>, {
                    onChange: (e: any) => {
                        handleChange(e)
                        onChange?.(e)
                    },
                    onBlur: handleBlur,
                    value: (onChange ? value : values?.[id]) || '',
                    isInvalid: errors?.[id] || false,
                })}
                {(true === showErrors && errors?.[id] && 'checkbox' !== child.props.type) && <span className={'d-block fs-9 pb-2 text-danger'}>{errors?.[id]}</span>}
            </>
        )
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema ?? {}}
            onSubmit={(values: any, rest: any) => {
                props.onSubmit?.(values, rest);
            }}
            action={props.action}
        >
            {({ handleSubmit, ...props }) => (
                <BsForm onSubmit={handleSubmit} className={className}>
                    {renderChildren({ children, ...{ ...props, showErrors } })}
                </BsForm>
            )}
        </Formik>
    );
};

export default Form;
