const CLOUDINARY_DOMAIN = `${process.env.CLOUDINARY_DOMAIN ?? ''}`

export const getCloudinaryParams = ({ quality, width, height }: { quality?: number | `${number}`, width?: number | string, height?: number | string }): string => {

    let param: string = ''
    const q: string = quality ? 'q_' + quality : 'q_auto'

    if (width || height) {
        param += (width && `w_${width}`) ?? ''
        param += (height && `${width && ','}h_${height}`) ?? ''
    }

    return '' !== param ? [param, 'c_fit', q].join(',') : ''
}

const getCDNImageUrl = ({ src, quality, width, height }: { src: string, quality?: number | `${number}`, width?: number | string, height?: number | string }): string => {

    let params = getCloudinaryParams({ quality, width, height })

    let url
    if (process.env.CLOUDINARY_DOMAIN) {
        const regex = new RegExp([process.env.COMMERCE_STORE_DOMAIN, process.env.COMMERCE_MEDIA_DOMAIN].join('|'), 'g')
        url = new URL(src.replace(regex, CLOUDINARY_DOMAIN ?? ''))
    } else {
        url = new URL(src)
    }

    if ((!url.pathname.endsWith('svg') && params)) {
        url.searchParams.append('tx', params)
    }

    return url.href
}

export default getCDNImageUrl
